// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("/opt/build/repo/src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-effects-api-mdx": () => import("/opt/build/repo/src/pages/effects/api.mdx" /* webpackChunkName: "component---src-pages-effects-api-mdx" */),
  "component---src-pages-effects-create-mdx": () => import("/opt/build/repo/src/pages/effects/create.mdx" /* webpackChunkName: "component---src-pages-effects-create-mdx" */),
  "component---src-pages-effects-intersection-mdx": () => import("/opt/build/repo/src/pages/effects/intersection.mdx" /* webpackChunkName: "component---src-pages-effects-intersection-mdx" */),
  "component---src-pages-effects-lifecycle-mdx": () => import("/opt/build/repo/src/pages/effects/lifecycle.mdx" /* webpackChunkName: "component---src-pages-effects-lifecycle-mdx" */),
  "component---src-pages-effects-mouse-mdx": () => import("/opt/build/repo/src/pages/effects/mouse.mdx" /* webpackChunkName: "component---src-pages-effects-mouse-mdx" */),
  "component---src-pages-effects-scrolling-mdx": () => import("/opt/build/repo/src/pages/effects/scrolling.mdx" /* webpackChunkName: "component---src-pages-effects-scrolling-mdx" */),
  "component---src-pages-examples-bokeh-mdx": () => import("/opt/build/repo/src/pages/examples/bokeh.mdx" /* webpackChunkName: "component---src-pages-examples-bokeh-mdx" */),
  "component---src-pages-examples-clouds-mdx": () => import("/opt/build/repo/src/pages/examples/clouds.mdx" /* webpackChunkName: "component---src-pages-examples-clouds-mdx" */),
  "component---src-pages-examples-fuzz-mdx": () => import("/opt/build/repo/src/pages/examples/fuzz.mdx" /* webpackChunkName: "component---src-pages-examples-fuzz-mdx" */),
  "component---src-pages-examples-light-mdx": () => import("/opt/build/repo/src/pages/examples/light.mdx" /* webpackChunkName: "component---src-pages-examples-light-mdx" */),
  "component---src-pages-examples-neon-mdx": () => import("/opt/build/repo/src/pages/examples/neon.mdx" /* webpackChunkName: "component---src-pages-examples-neon-mdx" */),
  "component---src-pages-examples-sale-mdx": () => import("/opt/build/repo/src/pages/examples/sale.mdx" /* webpackChunkName: "component---src-pages-examples-sale-mdx" */),
  "component---src-pages-examples-shader-mdx": () => import("/opt/build/repo/src/pages/examples/shader.mdx" /* webpackChunkName: "component---src-pages-examples-shader-mdx" */),
  "component---src-pages-examples-snow-mdx": () => import("/opt/build/repo/src/pages/examples/snow.mdx" /* webpackChunkName: "component---src-pages-examples-snow-mdx" */),
  "component---src-pages-examples-sparks-mdx": () => import("/opt/build/repo/src/pages/examples/sparks.mdx" /* webpackChunkName: "component---src-pages-examples-sparks-mdx" */),
  "component---src-pages-examples-toon-mdx": () => import("/opt/build/repo/src/pages/examples/toon.mdx" /* webpackChunkName: "component---src-pages-examples-toon-mdx" */),
  "component---src-pages-examples-torch-mdx": () => import("/opt/build/repo/src/pages/examples/torch.mdx" /* webpackChunkName: "component---src-pages-examples-torch-mdx" */),
  "component---src-pages-extlibs-mdx": () => import("/opt/build/repo/src/pages/extlibs.mdx" /* webpackChunkName: "component---src-pages-extlibs-mdx" */),
  "component---src-pages-fairy-mdx": () => import("/opt/build/repo/src/pages/fairy.mdx" /* webpackChunkName: "component---src-pages-fairy-mdx" */),
  "component---src-pages-fullscreen-clouds-mdx": () => import("/opt/build/repo/src/pages/fullscreen/clouds.mdx" /* webpackChunkName: "component---src-pages-fullscreen-clouds-mdx" */),
  "component---src-pages-fullscreen-shader-mdx": () => import("/opt/build/repo/src/pages/fullscreen/shader.mdx" /* webpackChunkName: "component---src-pages-fullscreen-shader-mdx" */),
  "component---src-pages-fullscreen-snow-mdx": () => import("/opt/build/repo/src/pages/fullscreen/snow.mdx" /* webpackChunkName: "component---src-pages-fullscreen-snow-mdx" */),
  "component---src-pages-html-2-canvas-mdx": () => import("/opt/build/repo/src/pages/html2canvas.mdx" /* webpackChunkName: "component---src-pages-html-2-canvas-mdx" */),
  "component---src-pages-index-mdx": () => import("/opt/build/repo/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-mixmode-mdx": () => import("/opt/build/repo/src/pages/mixmode.mdx" /* webpackChunkName: "component---src-pages-mixmode-mdx" */),
  "component---src-pages-quickstart-mdx": () => import("/opt/build/repo/src/pages/quickstart.mdx" /* webpackChunkName: "component---src-pages-quickstart-mdx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

